import Image from 'next/image'

type Props = {
  desktopBannerBgUrl: string
  desktopBannerUrl: string
  alt: string
}

const HeaderMd = (props: Props) => {
  const { desktopBannerUrl, desktopBannerBgUrl, alt } = props
  return (
    <header className='relative hidden bg-cover text-center mobileUp:block'>
      <Image
        src={desktopBannerBgUrl}
        alt={alt}
        className='z-0'
        fill
        style={{
          objectFit: 'cover',
        }}
        quality={60}
        sizes='100vw'
      />
      <Image
        src={desktopBannerUrl}
        alt={alt}
        width={800}
        height={255}
        style={{ width: 'auto', height: '100%' }}
        className='relative z-10'
      />
    </header>
  )
}

export default HeaderMd
